// Core
import React, { FC } from 'react'
import { Paper, Divider, Box } from '@material-ui/core'
// Components
import CreateButton from '../../../create-button'
import FoldersTree from '../../../folders-tree'

type SidebarProps = {
  showCreateButton?: boolean
}

const Sidebar: FC<SidebarProps> = ({ showCreateButton = true }) => {
  return (
    <Paper>
      {showCreateButton && (
        <>
          <Box px={2} py={1.5}>
            <CreateButton />
          </Box>
          <Divider />
        </>
      )}
      <Box padding={2}>
        <FoldersTree />
      </Box>
    </Paper>
  )
}

export default Sidebar
